import React from "react"

import SEO from "../components/common/seo"

import Breadcrumbs from "../components/common/breadcrumbs";
import LayoutSecondary from "../components/common/layout-secondary";
import {graphql} from "gatsby";

const breadcrumbs = [{
    text: 'Políticas corporativas',
    slug: '/es/politicas',
    active: false,
},{
    text: 'Política de proveedores',
    active: true,
}
];

const ProvidersPage = ({data, pageContext})  => (
    <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
        <SEO title="Política de proveedores - Tecalis"
             description={"Cualquier proveedor que trabaje para Tecalis deberá respetar nuestras políticas de proveedores."}/>
        <Breadcrumbs breadcrumbs={breadcrumbs}/>

        {/** Política de proveedores
         ================================================== */}
        <section className="pt-8 pt-md-9">
            <div className="container">

                {/** Heading */}
                <h1 className="display-4 font-weight-bold spacing">
                    Política de proveedores
                    <br/><br/>
                </h1>

                <div className="row check-list-qa">
                    <div className="col-12 col-md-12">

                        {/** Decoration */}
                        <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 mobile-qa"
                             style={{position: "absolute", zIndex: -10, top: "50px"}}>
                            <svg width="185" height="186" viewBox="0 0 185 186" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <circle cx="2" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="2" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="22" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="42" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="62" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="82" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="102" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="122" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="142" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="162" r="2" fill="#f43c51"/>
                                <circle cx="2" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="22" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="42" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="62" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="82" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="102" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="122" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="142" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="162" cy="182" r="2" fill="#f43c51"/>
                                <circle cx="182" cy="182" r="2" fill="#f43c51"/>
                            </svg>
                        </div>

                        {/** Image */}
                        <img src={require("../images/proveedores.png").default} alt="proveedores" className="img-fluid"
                             data-aos=""/>
                        <br/><br/><br/>

                        {/** Texto */}
                        <p className="font-size-lg text-black-80 mb-6 spacing">
                            Esta política de proveedores establece el procedimiento por el cual se regula la
                            relación de <b>Dexga Technologies S.L. (en adelante Tecalis)</b> con sus proveedores, en
                            el marco de la certificación en gestión de la calidad según la norma UNE-EN ISO 9001.
                        </p>

                        <p className="font-size-lg text-black-80 mb-6 spacing">Con motivo de la aplicación de la
                            misma, <b>Tecalis</b> debe comunicar a todos sus proveedores los requisitos que ha
                            establecido para la aprobación de los productos y servicios recibidos, así como la
                            competencia de la empresa y del personal del proveedor, las interacciones, el control y
                            seguimiento que el proveedor aplica en cumplimiento de los requisitos, entre otros.</p>

                        <p className="font-size-lg text-black-80 mb-6 spacing">La comunicación de los requisitos se
                            realizará a través de la hoja de pedido o documentos anexos a la solicitud de servicio.
                            El personal de <b>Tecalis</b> revisará el producto o servicio en el momento de la
                            recepción para verificar su adecuación. En caso de detectarse alguna incidencia, se
                            comunicará la misma al proveedor para su resolución.</p>

                        <p className="font-size-lg text-black-80 mb-6 spacing">Desde <b>Tecalis</b>, se valorará
                            anualmente la capacidad de servicio de sus proveedores en función de las no
                            conformidades abiertas durante el último ejercicio, pudiendo resolver el contrato en
                            caso de incumplimiento reiterado de los requisitos establecidos.</p>

                        <br/><br/><br/>

                    </div>

                </div>
            </div>
            {/** / .container */}
        </section>

    </LayoutSecondary>
);

export default ProvidersPage

export const providersPageQuery = graphql`
query providersPageQuery($lang: String!){
    cookies: markdownRemark(
            frontmatter: {
                lang: {
                eq: $lang
            }
                name: {
                eq: "cookies"
            }
            }){
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;

